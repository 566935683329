@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Custom-1";
    src: url("./assets/font/NewTegomin-Regular.ttf");
  }
  
  @font-face {
    font-family: "Custom-2";
    src: url("./assets/font/Nosifer-Regular.ttf");
  }